<template>
    <v-menu
        offset-y
        transition="slide-y-transition"
        nudge-bottom="10"
        min-width="140"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="black"
            class="primary--text button-hover"
            style="border: none;"
            dark
            outlined
            text
            depressed
            v-bind="attrs"
            v-on="on"
            >
              <v-icon
                normal
                left
                class="purple--text"
              >
                {{ attrs["aria-expanded"] == "false" ? "fa fa-caret-down" : "fa fa-caret-up" }}
              </v-icon>
               {{ $t(`Admin.menu.label`) }}
            </v-btn>
        </template>
        <v-list
            dense
        >
            <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                link
                :to="item.path"
                color="primary lighten-3"
            >
                <v-list-item-title class="profile-items">
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "AdminActionsMenu",

    data() {
        return {
            menuItems: [
                {
                    title: "Users list",
                    path: "/users",
                },
                // {
                //     title: "DIH listings",
                //     path: "/dih",
                // },{
                //     title: "SME listings",
                //     path: "/sme"
                // },
                {
                    title: "Innovation demonstrators",
                    path: "/innovation-demonstrators"
                },
                {
                    title: "News",
                    path: "/news",
                },

                {
                    title: "Events",
                    path: "/events",
                },
                
                {
                    title: "Newsletter",
                    path: "/newsletter"
                },
                {
                    title: "Newsletter Subscriptions",
                    path: "/newsletter-subscriptions"
                }
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.profile-items {
    font-size: 1em;
    font-weight: 600;
    color: var(--v-accent-darken4);
}
/* .button-background {
    border: 1px solid black;
    background: #ffffff !important;
    color:  #202020 !important;
} */
.button-hover {
    font-size: 1rem !important;
    font-weight: normal !important;
}
.button-hover:hover {
    border-bottom: 1px solid #33CC33 !important;
    background: #eee !important;
    transition-duration: 0.3s ease-in-out;
}
</style>