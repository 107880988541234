const setToken = ( { commit }, payload) => {
    localStorage.setItem("agrobofood_token", `${payload}`)
    commit("setToken", payload)
}

const removeToken = ( {commit}) => {
    localStorage.removeItem("agrobofood_token");
    commit("removeToken");
}

const setUserRole = ( {commit }, payload) => {
    localStorage.setItem("agrobofood_user_type", payload);
    commit("setUserRole", payload);
}

const removeUserRole = ( { commit }) => {
    localStorage.removeItem("agrobofood_user_type");
    commit("removeUserRole")
}

const setUserId = ( {commit }, payload) => {
    localStorage.setItem("agrobofood_user_id", payload);
    commit("setUserId", payload);
}

const removeUserId = ( { commit }) => {
    localStorage.removeItem("agrobofood_user_id");
    commit("removeUserId")
}

export default {
    setToken,
    removeToken,
    setUserRole,
    removeUserRole,
    setUserId,
    removeUserId,
}

