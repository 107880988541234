import Vue          from "vue";
import Vuex         from "vuex";
import auth         from "./modules/auth";
import formValues   from "./modules/formValues"
import toolbox      from "./modules/toolbox";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    formValues,
    toolbox,
  },
});
