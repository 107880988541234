<template>
    <v-autocomplete
        v-model="inputValue"
        :items="items"
        :label="label"
        :prepend-inner-icon="icons"
        :item-text="keyValue"
        item-value="id"
        outlined
        :multiple="multiple"
        :chips="!!multiple"
        dense
        :deletable-chips="!!multiple"
        required
        clearable
        :small-chips="!!multiple"
        :error-messages="inputValueErrors"
        @change="
            $v.inputValue.$touch();
            emit();
        "
    >
    </v-autocomplete>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
export default {
    name: "SelectInput",

    validations: {
        inputValue: {
            required: requiredIf(function () {
                return this.requiredInput;
            }),
        },
    },

    props: {
        currentValue: {
            type: [Array, Number, String],
            required: false,
            default: () => {
                return [];
            },
        },
        label: {
            required: true,
            type: String,
        },
        items: {
            required: true,
            type: [Array, Object],
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
        icons: {
            required: false,
            type: String,
            default: "",
        },
        keyValue: {
            required: false,
            default: "name",
            type: [String, Array],
        },
        requiredInput: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    data() {
        return {
            inputValue: [],
        };
    },

    computed: {
        inputValueErrors() {
            const errors = [];
            if (!this.$v.inputValue.$dirty) return errors;
                !this.$v.inputValue.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            return errors;
        },
    },

    methods: {
        emit() {
            this.$emit("update:input-value", this.inputValue);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
    },

    watch: {
        currentValue(newval) {
            if (newval) this.inputValue = newval;
        },
    },

    created() {
        if (this.currentValue) {
            this.inputValue = this.currentValue;
        }
    },
};
</script>

<style lang="scss" scoped></style>
