r<template>
    <v-container>
        <p class="text-body-1 font-weight-black">
             {{ $t("RegisterForm.OrgType.title") }}
        </p>
        <v-radio-group
            v-if="getOrgTypes"
            v-model="type"
            @change="updateType($event)"
        >
            <v-radio
                v-for="value in getOrgTypes"
                :key="value.id"
                :value="value.id" 
                :label="value.name"
            >
            </v-radio>
        </v-radio-group>
    </v-container>
</template>


<script>
// Vuex Imports
import { mapGetters } from "vuex"

export default {
    name: "OrganizationType",
    
    props: {
        orgType:{
            type:       Number,
            required:   false,
            default:    null,

        },

        formValid: {
            type:       Boolean,
            required:   false,
            default:    false,
        }
    },

    data(){
        return {
            type: this.orgType
        }
    },

    computed: {
        
        ...mapGetters({
            getOrgTypes: "formValues/getOrgTypes"
        }),

        formIsValid(){
            return this.type !== null
        }
    },

    /** 
    * Update methods that are used to update props (using .sync in the parent).
    * Updating formValid is for updating the given array's item that handles the next button. 
    */
    methods: {

        updateType(event){
            this.$emit("update:formValid", this.formIsValid)
            this.$emit("update:orgType", event);
        },
    },

    /** 
    * This wathcer is used to update the checked information
    * in case admin wants to see and change them
    */
    watch: {

        orgType(newVal){
            if(newVal) this.type = newVal;
        }
    }
}
</script>
