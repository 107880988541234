require("./overrides.scss");
export default {
  theme: {
    themes: {
      light: {
        primary:            "#33CC33",
        // primary:            "#42855B",
        secondary:          "#FFFFFF",
        purple:             "#C35272",
        accent:             "#9fb1bc",
        beigeBackground:    "#E8E6D3",
        // error:              "#a84451",
        error:              "#F55C47",
        info:               "#4a62ab",
        success:            "#31918e",
        // warning:            "#D9A152",
        warning:            "#FF5F00",
        blueish:            "#eaeeff",
        white:              "#fff",
        // black:              "#20262E",
        black:              "#3b4c55",
        linkHover:          "#e4e4e4",
        anchor:             "#fff",
      },
    },
  },
};
