<template>
    <v-container v-if="getReviewsStatus_Success" class="my-10">
        <h2 class="main-heading">
            {{ $t(`Admin.reviews.mainHeading`) }}
        </h2>
        <v-row>
            <v-col cols="12" md="7">
                <v-row v-for="(item, idx) in itemsToShow" :key="idx">
                    <v-col>
                        <base-review-card>
                            <template #user>
                                {{ item.user.name }}
                            </template>

                            <template #actions>
                                <dropdown-menu
                                    :is-text="true"
                                    :can-close="true"
                                    icon="fas fa-ellipsis-v"
                                >
                                    <template #listItems>
                                        <v-list>
                                            <v-list-item
                                                class="item-hover px-0"
                                                @click="editItem(item)"
                                            >
                                                <v-btn
                                                    text
                                                    color="accent darken-4"
                                                >
                                                    <v-icon
                                                        size="12"
                                                        class="mr-2"
                                                    >
                                                        fa-solid fa-pen
                                                    </v-icon>
                                                    <span
                                                        class="table-menu-item"
                                                    >
                                                        {{
                                                            $t(
                                                                `Admin.dataTable.tableActions.edit`
                                                            )
                                                        }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item
                                                class="item-hover px-0"
                                                @click="handleDelete(item)"
                                            >
                                                <v-btn
                                                    text
                                                    color="error lighten-1"
                                                >
                                                    <v-icon
                                                        size="12"
                                                        class="mr-2"
                                                    >
                                                        fa-solid fa-trash
                                                    </v-icon>
                                                    <span
                                                        class="table-menu-item"
                                                        >{{
                                                            $t(
                                                                `Admin.dataTable.tableActions.delete`
                                                            )
                                                        }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </template>
                                </dropdown-menu>
                            </template>

                            <template #rating>
                                <review-stars
                                    :rating.sync="item.rating"
                                ></review-stars>
                            </template>

                            <template #description>
                                {{ item.description }}
                            </template>
                        </base-review-card>
                    </v-col>
                </v-row>
                <v-row v-if="itemsToShow.length === 0">
                    There are no reviews
                </v-row>
                <v-row v-else-if="items.length > itemsToShow.length">
                    <v-col class="text-center">
                        <v-btn color="primary" @click="shownItems += 3">
                            {{ $t("Admin.reviews.showMore") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="isEditing">
                <review-card
                    :item="editingItem"
                    @confirm-edit="handleConfirmEdit()"
                    @cancel-edit="cancelEditing()"
                ></review-card>
            </v-col>
        </v-row>
        <confirm-modal :open="openModal" :close.sync="openModal">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Admin.reviews.deleteText`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten1"
                    class="mr-2"
                    :loading="deleteStatus_Pending"
                    @click="confirmDelete(deletingItem)"
                >
                    {{ $t(`Admin.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getDihReviews,
    deleteDihReview,
    updateDihReview,
} from "../../api/adminApi";

export default {
    name: "DihReviews",

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    components: {
        BaseReviewCard: () => import("@/components/base/BaseReviewCard.vue"),
        ReviewStars: () => import("@/components/ReviewStars.vue"),
        DropdownMenu: () => import("./components/DropdownMenu.vue"),
        TextArea: () => import("@/components/Inputs/TextArea.vue"),
        ConfirmModal: () => import("@/components/ConfirmModal.vue"),
        ReviewCard: () => import("./DihReviews/ReviewCard.vue"),
    },

    data() {
        return {
            getReviewsStatus: apiStatus.Idle,
            deleteStatus: apiStatus.Idle,

            items: [],
            shownItems: 3,

            isEditing: false,
            editingItem: {},
            deletingItem: {},

            openModal: false,
        };
    },

    computed: {
        ...apiStatusComputed([
            "getReviewsStatus",
            "deleteStatus",
            "editStatus",
        ]),

        itemsToShow() {
            return this.items.slice(0, this.shownItems);
        },
    },

    methods: {
        async getReviews() {
            this.getReviewsStatus = apiStatus.Pending;

            const payload = {
                id: this.id,
            };

            const { response, error } = await withAsync(getDihReviews, payload);

            if (error) {
                this.getReviewsStatus = apiStatus.Error;
                return;
            }

            this.items = response.data.data.user;

            this.getReviewsStatus = apiStatus.Success;
            this.editingItem = {};
            this.deletingItem = {};
        },

        editItem(item) {
            this.isEditing = true;
            this.editingItem = { ...item };
        },

        handleConfirmEdit() {
            this.isEditing = false;
            this.getReviews();
        },

        cancelEditing() {
            this.isEditing = false;
            this.editingItem = {};
        },

        async confirmDelete() {
            this.deleteStatus = apiStatus.Pending;

            const payload = {
                id: this.deletingItem.id,
            };

            const { response, error } = await withAsync(
                deleteDihReview,
                payload
            );

            if (error) {
                this.deleteStatus = apiStatus.Error;
                return;
            }

            this.deleteStatus = apiStatus.Success;

            this.openModal = false;
            this.getReviews();
        },

        handleDelete(item) {
            this.deletingItem = { ...item };
            this.openModal = true;
        },
    },

    created() {
        this.getReviews();
    },
};
</script>

<style scoped></style>
