const getOrgTypes = state => state.formValues.organizations;
const getTestBeforeInvest = state => state.formValues.test_before_invest;
const getSupportForInvestment = state => state.formValues.support_to_find_investments;
const getEcosystemNetworking = state => state.formValues.ecosystem_networking;
const getSkillsTraining = state => state.formValues.skills_training;
const getTechnologyFields = state => state.formValues.technology_fields || [];
const getInnovationFields = state => state.formValues.innovation_fields || [];
// const getAgrifoodSectors = state => state.formValues.agrifood_sectors;
// const getDataCollection = state => state.formValues.data_collection;
// const getAnalytics = state => state.formValues.analytics;
// const getAutomation = state => state.formValues.automation;


export default {
    getOrgTypes,
    getTestBeforeInvest,
    getSupportForInvestment,
    getEcosystemNetworking,
    getSkillsTraining,
    getTechnologyFields,
    getInnovationFields
    // getAgrifoodSectors,
    // getDataCollection,
    // getAnalytics,
    // getAutomation,
}