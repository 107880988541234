<template>
    <div>
        <p class="text-body-1 font-weight-black">
            {{ $t("RegisterForm.Country.title") }}
        </p>
        <v-autocomplete
            v-if="getCountriesStatus_Success"
            ref="dropdownCountry"
            v-model="dropdownCountry"
            :items="countries"
            clearable
            dense
            outlined
            item-text="name"
            item-value="id"
            label="Country"
            @change="updateCountry($event)"
        ></v-autocomplete>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { getCountries } from "../../api/formValuesApi.js";

export default {
    name: "Country",

    props: {
        country: {
            type: Number,
            required: false,
            default: null,
        },

        formValid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            getCountriesStatus: apiStatus.Idle,
            countries: [],
            countryObjects: [],

            dropdownCountry: "",
        };
    },

    computed: {
        ...apiStatusComputed("getCountriesStatus"),

        formIsValid() {
            return this.dropdownCountry && this.dropdownCountry !== "";
        },
    },

    methods: {
        /**
         * Fetching Countries with API call.
         * Set this.countries to an array with only the names and countryObjects to an array
         * which keeps all the country data, so we can get back an id.
         */

        async fetchCountries() {
            this.getCountriesStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getCountries);

            if (error) {
                this.getCountriesStatus = apiStatus.Pending;
                return;
            }

            this.getCountriesStatus = apiStatus.Success;

            this.countries = response.data.countries;
            this.setCountry();
        },

        setCountry() {
            const initCountry = this.countryObjects.find(
                (item) => item.id === this.country
            );
            this.dropdownCountry = initCountry?.id || null;
        },

        /**
         * Update methods that are used to update props (using .sync in the parent).
         * we use the .find method to find the picked country's id and return it.
         * Updating formValid is for updating the given array's item that handles the next button.
         */
        updateCountry(e) {
            if (e) {
                this.$emit("update:country", e);
            } else {
                this.$emit("update:country", 0);
            }
            this.$emit("update:formValid", this.formIsValid);
        },
    },

    /**
     * This wathcer is used to update the checked information
     * in case admin wants to see and change them
     */

    watch: {
        country(newVal) {
            if (newVal) this.setCountry();
        },
    },

    created() {
        this.apiStatus = apiStatus;
        this.fetchCountries();
    },
};
</script>
