<template>
    <v-app-bar app height="100" class="nav-background">
        <v-container>
            <v-row>
                <v-col>
                    <v-row align="center">
                        <v-col cols="8" sm="6" md="3" lg="2" class="px-1">
                            <router-link to="/">
                                <v-img
                                    src="../../assets/agrobofood_logo_remastered.svg"
                                    max-height="55"
                                    min-height="30"
                                    max-width="230"
                                    min-width="130"
                                    alt="agrobofood logo"
                                >
                                </v-img>
                            </router-link>
                        </v-col>
                        <v-col
                            class="text-right"
                            cols="4"
                            sm="6"
                            md="9"
                            lg="10"
                            align-self="center"
                        >
                            <v-container
                                v-if="
                                    $vuetify.breakpoint.xs ||
                                    $vuetify.breakpoint.sm
                                "
                            >
                                <v-btn
                                    depressed
                                    plain
                                    rounded
                                    @click="toggleMenu()"
                                >
                                    <v-icon color="black"> fas fa-bars </v-icon>
                                </v-btn>
                                <v-navigation-drawer
                                    v-model="menuIsShown"
                                    right
                                    absolute
                                    temporary
                                    height="1000"
                                >
                                    <v-list>
                                        <v-list-item-group>
                                            <a
                                                href="https://agrobofood.eu/"
                                                target="_blank"
                                                class="text-decoration-none nav black--text"
                                            >
                                                <v-list-item>
                                                    Agrobofood Page
                                                </v-list-item>
                                            </a>
                                            <router-link
                                                to="/maturity-tool"
                                                class="text-decoration-none nav"
                                                exact
                                            >
                                                <v-list-item>
                                                    Maturity Tool
                                                </v-list-item>
                                            </router-link>
                                            <v-list-item v-if="userIsAdmin">
                                                <admin-actions-menu></admin-actions-menu>
                                            </v-list-item>

                                            <router-link
                                                v-else
                                                :to="{ name: 'MyListings' }"
                                                class="text-decoration-none nav"
                                                exact
                                            >
                                                <v-list-item>
                                                    My Listings
                                                </v-list-item>
                                            </router-link>
                                            <v-list-item
                                                class="font-weight-black error--text nav"
                                                @click="logout()"
                                            >
                                                Logout
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-navigation-drawer>
                            </v-container>
                            <v-container v-else>
                                <a
                                    href="https://agrobofood.eu/"
                                    target="_blank"
                                >
                                    <v-btn
                                        class="p-3 ma-4 nav"
                                        depressed
                                        outlined
                                    >
                                        Agrobofood Page
                                    </v-btn>
                                </a>
                                <router-link
                                    to="/maturity-tool"
                                    class="text-decoration-none"
                                    exact
                                >
                                    <v-btn
                                        class="p-3 ma-4 nav"
                                        depressed
                                        outlined
                                    >
                                        Maturity Tool
                                    </v-btn>
                                </router-link>
                                <router-link
                                    to="/my-solutions"
                                    class="text-decoration-none"
                                    exact
                                >
                                    <v-btn
                                        class="p-3 ma-4 nav"
                                        depressed
                                        outlined
                                    >
                                        My Solutions
                                    </v-btn>
                                </router-link>
                                <admin-actions-menu
                                    v-if="userIsAdmin"
                                ></admin-actions-menu>
                                <router-link
                                    v-else
                                    to="/my-listings"
                                    class="text-decoration-none"
                                    exact
                                >
                                    <v-btn
                                        class="p-3 ma-4 nav"
                                        depressed
                                        outlined
                                    >
                                        My Listings
                                    </v-btn>
                                </router-link>
                                <v-btn
                                    v-if="userIsLogged"
                                    outlined
                                    height="40"
                                    width="40"
                                    fab
                                    depressed
                                    :to="{ name: 'UserProfile' }"
                                >
                                    <v-icon small> fas fa-user </v-icon>
                                </v-btn>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync.js";

import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import AdminActionsMenu from "@/components/AdminActionsMenu.vue";

// Vuex Imports
import { mapGetters } from "vuex";

export default {
    name: "TheNavbar",

    components: {
        AdminActionsMenu,
    },

    data() {
        return {
            menuIsShown: false,
        };
    },
    computed: {
        ...apiStatusComputed("logoutStatus"),

        ...mapGetters({
            userIsLogged: "auth/isLogged",
            userIsAdmin: "auth/userIsAdmin",
        }),
    },

    methods: {
        /**
         * Handles the toggle of the side menu(shown only in mobile view)
         */
        toggleMenu() {
            if (this.menuIsShown) {
                this.menuIsShown = false;
            } else {
                this.menuIsShown = true;
            }
        },

       
    },
};
</script>

<style scoped>
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: none;
    border-bottom: 4px solid var(--v-primary-base) !important;
}
.nav-background {
    background-image: linear-gradient(110deg, #ffffff 43%, #d6f5d6 100%);
}

.router-link-active {
    color: #33cc33;
}

.v-btn.v-size--default {
    font-size: 1rem;
}

.router-link-active button {
    color: white;
    border: none;
    background-color: #202020;
    /* background-color: #33cc33; */
}
</style>
