import { api, apiObject } from "./api";
import store from "../store";

const URLS = {
    user: "admin/user",
    users: "admin/users",
    restoreUsers: "admin/deleted-users",
    restoreUsersById: "admin/restore",
    downloadUsersExcel: "admin/users/export",
    deleteUser: "admin/delete",
    updateUser: "admin/edit",
    getSpecificUser: "admin/specific-user",
    dihDirectories: "dih/directories",
    dihDirectory: "dih/directory",
    dihReviews: "dih/directory/review",
    adminDihDirectory: "admin/dih/directory",
    smeDirectories: "sme/directories",
    adminSmeDirectory: "admin/sme/directory",
    smeDirectory: "sme/directory",

    demonstrators: "sme/demonstrators",
    singleDemonstrator: "sme/demonstrator",

    news: "news",
    createNew: "admin/news-and-events",

    newsletterSubscriptions: "newsletter",
    events: "events",
    newsTypes: "news-events/types",

    newsletterList: "newsletter-articles",
    adminNewslettetArticles: "admin/newsletter-articles",
    adminNewsletter: "admin/newsletter",

    suggestedServices: "/service_box/suggested",
    serviceGroupList: "service_box/groups_data",
    serviceLanguageList: "service_box/language_data",
    allServices: "/service_box",
    serviceById: "/service_box/show",
    createService: "/service_box",
    updateService: "/service_box",
    deleteService: "/service_box",
    filterServices: "/service_box",
};

/**Users */
export const getUsers = (payload) => {

    // return api.get(`${URLS.users}?filter=${payload.search}&page=${payload.page || 1}&items=${payload.items || 1}`)
    
    if(payload.search != '') {
        return api.get(`${URLS.users}?filter=${payload.search}&page=${payload.page || 1}&items=${payload.items || 1}`);
        // return api.get(`${URLS.users}?page=${payload.page || 1}&items=${payload.items || 1}`)
    } else {
        return api.get(`${URLS.users}?page=${payload.page || 1}&items=${payload.items || 1}`);
    }
};

// Get Deleted Users
export const getDeletedUsers = (payload) => {
    if(payload.search != '') {
        return api.get(`${URLS.restoreUsers}?filter=${payload.search}&page=${payload.page || 1}&items=${payload.items || 1}`);
        // return api.get(`${URLS.users}?page=${payload.page || 1}&items=${payload.items || 1}`)
    } else {
        return api.get(`${URLS.restoreUsers}?page=${payload.page || 1}&items=${payload.items || 1}`);
    }
};

// Download Excel For all the Users
export const downloadUsersExcel = (payload) => {

    const params = payload ? `?deleted=${payload.deleted}` : '';

    return api.post(`${URLS.downloadUsersExcel}${params}`, null, {
        responseType: 'blob',
    });

    // if (payload.deleted == undefined) {
    //     return api.post(`${URLS.downloadUsersExcel}`, null, {
    //         responseType: "blob",
    //     });
    // } else {
    //     return api.post(`${URLS.downloadUsersExcel}?deleted=${payload.deleted}`, null, {
    //         responseType: "blob",
    //     });
    // }
};

// Restore User By Id
export const restoreUsersById = (payload) => {
    return api.put(`${URLS.restoreUsersById}/${payload.id}`)
};

export const updateUser = (payload) => {
    return api.put(`${URLS.updateUser}/${payload.id}`, payload.data);
};

export const getSpecificUser = (payload) => {
    return api.get(`${URLS.getSpecificUser}/${payload}`);
};

export const toggleUserStatus = ({ id, enable }) => {
    return api.post(`${URLS.user}/${id}/toggle-status`, { enable });
};

/**DIHs */
export const getDih = (payload) => {
    return api.get(
        `${URLS.dihDirectories}?page=${payload.page || 1}&items=${
            payload.items || 1
        }`
    );
};

export const getSingleDih = (payload) => {
    return api.get(`${URLS.dihDirectory}/${payload.id}`);
};

export const getDihReviews = (payload) => {
    return api.get(`${URLS.dihReviews}/${payload.id}`);
};

export const updateDihReview = (payload) => {
    return api.patch(`${URLS.dihReviews}/${payload.id}`, payload.data);
};

export const deleteDihReview = (payload) => {
    return api.delete(`${URLS.dihReviews}/${payload.id}`);
};

export const createDih = (payload) => {
    let url = URLS.dihDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.dihDirectory}`;
    return api.post(url, payload);
};

export const updateDih = (payload) => {
    let url = URLS.dihDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.dihDirectory}`;
    return api.post(`${url}/${payload.id}`, payload.data);
};

export const deleteDih = (payload) => {
    let url = URLS.dihDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.dihDirectory}`;
    return api.delete(`${url}/${payload.id}`);
};

/**SMEs*/
export const getAllSmes = (payload) => {
    return api.get(
        `${URLS.smeDirectories}?page=${payload.page || 1}&items=${
            payload.items || 1
        }`
    );
};

export const deleteSme = (payload) => {
    let url = URLS.smeDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.smeDirectory}`;
    return api.delete(`${url}/${payload.id}`);
};

export const createSme = (payload) => {
    let url = URLS.smeDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.smeDirectory}`;
    return api.post(url, payload);
};

export const getSingleSme = (payload) => {
    return api.get(`${URLS.smeDirectory}/${payload.id}`);
};

export const updateSme = (payload) => {
    let url = URLS.smeDirectory;
    if (store.getters["auth/userIsAdmin"]) url = `admin/${URLS.smeDirectory}`;
    return api.post(`${url}/${payload.id}`, payload.data);
};

/**Demonstrators */
export const getAllDemonstrators = (payload) => {
    return api.get(
        `${URLS.demonstrators}?page=${payload.page || 1}&items=${
            payload.items || 1
        }`
    );
};

export const deleteDemonstrator = (payload) => {
    return api.delete(`${URLS.singleDemonstrator}/${payload.id}`);
};

export const createDemonstrator = (payload) => {
    return api.post(URLS.singleDemonstrator, payload);
};

export const getSingleDemonstrator = (payload) => {
    return api.get(`${URLS.singleDemonstrator}/${payload.id}`);
};

export const updateDemonstrator = (payload) => {
    return api.post(`${URLS.singleDemonstrator}/${payload.id}`, payload.data);
};

/**News */
export const getNewsTypes = () => {
    return api.get(`${URLS.newsTypes}`);
};

export const getAllNews = (payload) => {
    return api.get(
        `${URLS.news}?page=${payload.page || 1}&items=${payload.items || 1}`
    );
};

export const getSingleNew = (payload) => {
    return api.get(`${URLS.news}/${payload.id}`);
};

export const createNew = (payload) => {
    return api.post(URLS.createNew, payload);
};

export const updateNew = (payload) => {
    return api.post(`${URLS.createNew}/${payload.id}`, payload.data);
};

export const deleteNew = (payload) => {
    return api.delete(`${URLS.createNew}/${payload.id}`);
};

/**Events */
export const getAllEvents = (payload) => {
    return api.get(
        `${URLS.events}?page=${payload.page || 1}&items=${payload.items || 1}`
    );
};

export const getSingleEvent = (payload) => {
    return api.get(`${URLS.events}/${payload.id}`);
};

export const deleteEvent = (payload) => {
    return api.delete(`${URLS.createNew}/${payload.id}`);
};

/**Subscriptions */
export const getSubscriptions = (payload) => {
    return api.get(
        `${URLS.newsletterSubscriptions}?page=${payload.page || 1}&items=${
            payload.items || 1
        }`
    );
};

export const addSubscription = (payload) => {
    return api.post(`${URLS.newsletterSubscriptions}`, payload);
};

export const editSubscription = (payload) => {
    return api.patch(`${URLS.adminNewsletter}/${payload.id}`, payload.data);
};

export const deleteSubscription = ({ id }) => {
    return api.delete(`${URLS.adminNewsletter}/${id}`);
};

/** Newsletter */

export const getNewsletterList = () => {
    return api.get(`${URLS.newsletterList}`);
};

export const getNewsletterArticle = ({ id }) => {
    return api.get(`${URLS.newsletterList}/${id}`);
};

export const createNewsletterArticle = (payload) => {
    return api.post(`${URLS.adminNewslettetArticles}/create`, payload);
};

export const updateNewsletterArticle = ({ id, data }) => {
    return api.post(`${URLS.adminNewslettetArticles}/update/${id}`, data);
};

export const deleteNewsletterArticle = ({ id }) => {
    return api.delete(`${URLS.adminNewslettetArticles}/delete/${id}`);
};

//* Delete / Update user profile images */

export const update_logo_picture = ({ type, data }) => {
    return api.post(type, data);
};

//* Admin [ Create / Edit / Delete ] Toolbox Services */

export const getToolBoxServices = (payload) => {
    // return api.get(`${URLS.allServices}`, payload);
    return api.get(
        `${URLS.allServices}?page=${payload.page || 1}&items=${
            payload.items || 1
        }`
    );
};

export const getToolBoxServiceGroupList = () => {
    return api.get(`${URLS.serviceGroupList}`);
};

export const getToolBoxServiceLanguageList = () => {
    return api.get(`${URLS.serviceLanguageList}`);
};

export const getSuggestedToolBoxServices = () => {
    return api.get(`${URLS.suggestedServices}`);
};

export const getToolBoxService = (payload) => {
    return api.get(`${URLS.serviceById}/${payload}`);
};

export const getToolBoxServicesByFiltering = (payload) => {

    let formatType = payload.type == null ? '' : payload.type; 
    // GET /service_box?filter[languages]=1,2&filter[groups]=1,2&filter[type]=test1

    return api.get(`${URLS.filterServices}?page=${payload.page || 1}&items=${payload.items || 1}&filter[languages]=${payload.language}&filter[groups]=${payload.groups}&filter[type]=${formatType}&filter[title]=${payload.title}`,
    // { 
    //    params: {
    //         type: 1,
    //         language: 1
    //    }
    // }
     )
}

export const createToolBoxService = (payload) => {
    return api.post(`${URLS.createService}`, payload);
};

export const deleteToolBoxService = (payload) => {
    return api.delete(`${URLS.deleteService}/${payload}`);
};

export const updateToolBoxService = (payload) => {
    return api.post(`${URLS.updateService}/${payload.id}`, payload.data);
};


// Admin Delete Users

export const deleteUser = (payload) => {
    return api.delete(`${URLS.deleteUser}/${payload.id}`)
}
