<template>
    <v-app>
        <layout></layout>
    </v-app>
</template>

<script>
//Component Imports
import Layout from "./layout/Layout.vue"

export default {
    name: "App",

    components: {
        Layout,
    },

    data: () => ({
        //
    }),
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap");
@import url("./styles/common.scss");

.v-input__icon.v-input__icon--prepend-inner i {
  font-size: 1rem !important;
  color: var(--v-primary-base) !important;
}
.v-input__icon.v-input__icon--prepend-inner i:hover {
    color: var(--v-error-base) !important;
}
</style>
