<template>
    <v-card class="shadow">
        <v-card-title>
            <v-row>
                <v-col>
                    <slot name="user" />
                    <slot name="rating" />
                </v-col>
                <v-col class="text-right">
                    <slot name="actions" />
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <slot name="description" />
        </v-card-text>

        <v-card-actions class="justify-end">
            <slot name="bottom-actions" />
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "BaseCard",
};
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;
}
</style>
