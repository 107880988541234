import Vue          from    "vue";
import App          from    "./App.vue";
import router       from    "./router";
import store        from    "./store";
import vuetify      from    "./plugins/vuetify/index";
import i18n         from    "./plugins/i18n";
import Vuelidate    from    "vuelidate";
import                      "@fortawesome/fontawesome-free/css/all.css";
import                      "@/plugins/dayjs";

/**
* Globally register all `_base`-prefixed components
*/
import "./helpers/globals.js"

/**
* Leaflet specific configuration
*/
import { Icon } from 'leaflet'
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});



Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    name: "Agrobofood",
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
