import TheNavbar from "../components/base/TheNavbar.vue";
import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";

export default [
    {
        path: "/associated-membership-terms",
        name: "DihTerms",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "DihTerms" */ "../views/DihTerms.vue"
                ),
        },
        meta: {
            title: 'DIH Terms'
        }
    },

    {
        path: "/business-membership-terms",
        name: "SmeTerms",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "SmeTerms" */ "../views/SmeTerms.vue"
                ),
        },
        meta: {
            title: 'SME Terms'
        }
    },

    {
        path: "/privacy-terms",
        name: "PrivacyTerms",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "PrivacyTerms" */ "../views/PrivacyTerms.vue"
                ),
        },
        meta: {
            title: 'Privacy Terms'
        }
    },
];
