<template>
    <v-card
        class="shadow pa-2 pa-sm-6"
        :min-height="cardHeight" 
    >
        <slot name="content" />
    </v-card>
</template>

<script>
export default {
    name: "BaseCard",
    props: {
        cardHeight: {
            type: String,
            required: false,
            default: "434"
        }
    }
}
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: rgba(22, 22, 22, 0.1) 0px 2px 8px 0px !important;
    /* box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important; */
}
</style>