<template>
    <div class="login__container">
        <v-card
            class="login_form__container"
            max-width="700"
            max-height="1000"
            >
            <v-img
                src="../../assets/logoagro.png"
                max-height="100"
                max-width="100"
                alt="agrobofood logo"
                class="login_form__logo"
            >
            </v-img>
            <v-stepper
                v-model="step"
                class="elevation-0"
            >
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div class="login_form_info">
                            <p>
                                <span class="primary--text login_span">Welcome to agROBOfood network!</span>
                                <br>
                                <br>
                                Digital Innovation Hub(s) (Associated Members) and SMEs, Companies (Business Members) can become part of the agROBOfood network in a few easy steps by filling out our questionnaire!
                                <br>
                                <br>
                                <strong>As an Associated Member of agROBOfood network you can leverage:</strong>
                                <br>
                                i) Collaborative Networks: Partner with industry leaders, researchers, and other hubs;
                                <br>
                                ii) Access to Technology: Stay updated with cutting-edge agricultural innovations; 
                                <br>
                                iii) Funding and Support: Obtain financial backing for your projects;
                                <br>
                                iv) Expertise Sharing: Gain and share valuable knowledge;
                                <br>
                                v) Visibility: Enhance your profile and credibility in the sector;
                                <br>
                                vi) Market Expansion: Explore new markets and segments;
                                <br>
                                vii) Training: Access workshops and development programs;
                                <br>
                                viii) Innovation Ecosystem: Be part of a dynamic, supportive community driving agricultural innovation.
                                <br>
                                <br>
                                <strong>As a Business Member of agROBOfood network you can leverage:</strong>
                                <br>
                                i) Independent innovation mentoring via our network of DIHs and CCs;
                                <br>
                                ii) Connection to EU-level Testing and Experimentation Facilities (TEFs); Participation in matchmaking for market and business model analysis through our network of DIHs and CCs;
                                <br>
                                iii) Access to local one-stop-shops in your region to connect with farming organizations, cooperatives, and farmers interested in digital agri-food solutions;
                                <br>
                                iv) Listing of your organization in our business directory;
                                <br>
                                v) Participation in our "Pitch Your Robot, Find Your Investor" event, including pitch training;
                                <br>
                                vi) Access to listings of public and private funding opportunities through our website; and
                                <br>
                                vii) Access to listings of training programs provided by DIHs and CCs in our network.
                                <br>
                                <br>
                                See below the access you have to agROBOfood services either as an associated or a business member: 
                            </p>
                            <!-- First -->
                            <br>
                            <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Skills & Training services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in skills"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Second -->
                            <br>
                            <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Ecosystem networking​ Services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in ecosystem"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Third -->
                             <br>
                             <br>
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Test before invest services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in testBeforeInvest"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Fourth -->
                             <br>
                             <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Support to find investments services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in supportInvestments"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card-title>
                            <v-row>
                                <v-col cols="12">
                                    <h2 class="login_span text-center">
                                        {{ $t("Login.title") }}
                                    </h2>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-form
                            class="login_form"
                            @submit.prevent="login()"
                        >
                            <v-card-text class="py-0">
                                <v-text-field
                                    v-model="email"
                                    :error-messages="emailErrors"
                                    :label="$t('Login.fields.email')"
                                    required
                                    outlined
                                    dense
                                    @input="handleEmailInput()"
                                    @blur="$v.email.$touch()"
                                ></v-text-field>

                                <v-text-field
                                    v-model="password"
                                    :append-icon="
                                        showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                                    "
                                    :type="showPassword ? '' : 'password'"
                                    :error-messages="passwordErrors"
                                    :label="$t('Login.fields.password')"
                                    required
                                    outlined
                                    dense
                                    @input="handlePasswordInput"
                                    @blur="$v.password.$touch()"
                                    @click:append="showPassword = !showPassword"
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="d-flex flex-column">
                                <v-alert
                                    :value="showErrorAlert"
                                    type="error"
                                    dense
                                    transition="slide-x-transition"
                                >
                                    {{ errorAlertText }}
                                </v-alert>
                                <v-btn
                                    type="submit"
                                    class="text-button submit-button-hover"
                                    color="primary"
                                    depressed
                                    :disabled="formNotValid"
                                    :loading="loginStatus_Pending"
                                >
                                    {{ $t("Login.fields.loginBtn") }}
                                </v-btn>

                                <v-container class="mt-5 pa-0 text-center">
                                    <span> {{ $t("Login.forgotPassword") }} </span>
                                    <router-link to="/forgot-password">
                                        <span class="purple--text">
                                            {{ " " + $t("Login.resetPass") }}
                                        </span>
                                    </router-link>
                                </v-container>
                                <router-link to="/register">
                                    <p class="black--text text-center text--disabled mt-5">
                                        {{ $t("Login.doesntHave") }}
                                    </p>
                                </router-link>
                            </v-card-actions>
                        </v-form>
                    </v-stepper-content>
                </v-stepper-items>
        </v-stepper>
        <v-card-actions class="mt-auto">
            <v-row
                justify="start"
                align-content="start"
                class="pa-5"
            >
            <v-col
                v-if="step > 1"
                cols="4"
                align-self="center"
                class="text-left"
            >
                <v-btn
                    color="primary"
                    depressed
                    class="submit-button-hover"
                    @click="handleBack()"
                >
                    {{ $t("General.back") }}
                </v-btn>
            </v-col>
            <v-col
                v-if="step === 1"
                cols="4"
                align-self="center"
                class="text-left"
            >
                <v-btn
                    color="primary"
                    depressed
                    class="submit-button-hover"
                    @click="handleNextStep()"
                >
                    {{ $t("General.next") }}
                </v-btn>
            </v-col>
            </v-row>
        </v-card-actions>
        </v-card>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { loginUser } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { apiObject } from "../../api/api";

// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    name: "Login",

    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLengthValue: minLength(3),
        },
    },

    data() {
        return {
            skills: [
                {
                    name: "Maturity tool",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Service box",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Ethics awareness",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Digital skills",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Inonovation skills",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Regulatory awareness",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            ecosystem: [
                {
                    name: "DIH Directory",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Forum",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Matchmaking",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Newsletter",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Events",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            testBeforeInvest: [
                {
                    name: "Technical trainings and (interegional) demos​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Standardisation needs​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Technical Mentoring services​​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Mapping of robotics technologies in agri-food​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Best Practices in Developing Open Platforms",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            supportInvestments: [
                {
                    name: "agROBOfood sustainability plan",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Business Member Directory and Portal",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Business Mentoring services",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Pitch your robot event",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Connection to funding sources",
                    associated: "",
                    business: "✔️",
                }
            ],
            step: 1,
            email: "",
            password: "",
            showPassword: false,

            loginStatus: apiStatus.Idle,

            showErrorAlert: false,
            errorAlertText: "There was an error",
        };
    },

    computed: {
        ...apiStatusComputed("loginStatus"),

        /**
         * Following functions are used to validate the form on touch and blur
         * @summary Using vuelidate we can check for errors using vuelidate mixins and validations
         * @return {Array} returns array with errors to be shown under the text fields.
         */

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLengthValue &&
                errors.push(this.$tc("ErrorMessages.minLength", 3));
            !this.$v.password.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formNotValid() {
            return this.$v.$invalid;
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload.
         * If the credentials are valid it sets the Authorization header
         * to the access_token and dispatches 3 actions of the the vuex store.
         * The first sets the token in the global state and the localStorage.
         * The second sets the user_role in the global state and the localStorage.
         * The third sets the user_id in the global state and the localStorage.
         * At lat redirects the user to the home page
         */

         handleNextStep() {
            this.step = this.step + 1;
         },
         
         handleBack() {
            this.step = this.step - 1;
         },

        async login() {
            this.loginStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
                password: this.password,
            };

            const { response, error } = await withAsync(loginUser, payload);

            if (error) {
                this.loginStatus = apiStatus.Error;
                this.showErrorAlert = true;
                if (error.response.status === 500) {
                    this.errorAlertText = error.response.statusText;
                    return;
                }
                this.errorAlertText =
                    error.response.data.message || error.response.data;
                return;
            }
            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.access_token}`;

            this.loginStatus = apiStatus.Success;

            const user_type = response.data.user_type.toString();
            const user_id = response.data.user_id;

            this.$store.dispatch("auth/setToken", response.data.access_token);
            this.$store.dispatch("auth/setUserRole", user_type);
            this.$store.dispatch("auth/setUserId", user_id);
            
            const cookieOptions = {
                token: response.data.access_token,
                domain: ".agrobofood.eu", //need to change with agrobofood subdomain to work with cross-domain cookie
                SameSite: "Strict",
                maxAge: "",
            };

            //Create cookie with wanted values
            document.cookie = `token=${cookieOptions.token};Domain=${cookieOptions.domain};SameSite=${cookieOptions.SameSite};`;
            // document.cookie= `name=${};`

            //If user is admin, redirect him in admin page on login,
            //else go to the home page

            if (user_type === "0") this.$router.push("/users");
            else this.$router.push("/home");
        },

        handlePasswordInput() {
            this.showErrorAlert = false;
            this.$v.password.$touch();
        },

        handleEmailInput() {
            this.showErrorAlert = false;
            this.$v.email.$touch();
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.login__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.login_span {
    font-size: 1.3rem;
    transform: translateY(-30px);
}

.login_form__container {
    /* background: rgba(255, 255, 255, 0.6); */
    border-radius: 10px;
    width: 80%;
    height: 60%;
    margin: 50px;
}

.login_form {
    width: 100%;
    height: 360px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}
.login_form_info {
    width: 100%;
    height: 450px;
    overflow-y: auto;
}

.login_form__logo {
    transform: translate(-50px, -50px);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.login__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}

.login__container {
    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    animation: animateBackground 10s ease infinite;
}
</style>
