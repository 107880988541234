import { api } from "./api";

const URLS = {
    maturityUrl: "maturity-url",
}


export const getMaturityUrl = () => {    
    return api.get(`${URLS.maturityUrl}`);
};
