<template>
    <v-text-field
        v-model.trim="email"
        color="primary"
        :dense="dense"
        outlined
        :error-messages="errors"
        :prepend-inner-icon="icons"
        :label="label"
        @input="
            $v.email.$touch();
            emit();
        "
        @blur="
            $v.email.$touch();
            emit();
        "
    >
    </v-text-field>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "EmailInput",

    validations() {
        return {
            email: {
                required,
                email,
            },
        };
    },

    props: {
        value: {
            required: true,
        },
        dense: {
            required: false,
            type: Boolean,
            default() {
                return true;
            },
        },
        label: {
            type: String,
            required: true
        },
        icons: {
            required: false,
            type: String,
            default: '',
        }
    },

    data() {
        return {
            email: "",
        };
    },

    computed: {
        errors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.email.email &&
                errors.push(this.$i18n.t(`ErrorMessages.validEmail`));
            return errors;
        },
    },

    methods: {
        emit() {
            this.$emit("input", this.email);
            this.$emit("update:inValid", this.$v.$invalid);
        },
    },

    watch: {
        value: {
            handler() {
                this.email = this.value;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped></style>
