import { api, apiObject } from "./api";

const URLS = {
    login:      "login",
    register:   "register",
    logout:     "logout",
    forgot:     "forgot-password",
    reset:      "reset-password",
    verify:     "verify"
};

export const loginUser = payload => {
    
    return api.post(`${URLS.login}`, { ...payload });
};

export const registerUser = payload => {

    return api.post(`${URLS.register}`, payload );
};

export const forgotPassword = payload => {

    return api.post(`${URLS.forgot}`, payload );
};

export const logoutUser = () => {

    api.post(`${URLS.logout}`);
    return delete apiObject.defaults.headers.common["Authorization"];
};

export const resetPassword = payload => {

    return api.put(`${URLS.reset}`, payload );
};

export const verifyUser = payload => {
    return api.post(`${URLS.verify}`, {...payload})
}