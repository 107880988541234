<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="400px"
    >
        <v-card>
            <v-card-title :class="$attrs['title-classes']">
                {{ title }}
            </v-card-title>
            <v-card-text :class="$attrs['text-classes']">
                {{ text }}
            </v-card-text>

            <v-card-actions class="justify-end">
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "BaseDialog",

    inheritAttrs: false,

    props: {
        show: {
            type:       Boolean,
            required:   true,
        },
        title: {
            type:       String,
            required:   true,
        },

        text: {
            type:       String,
            required:   true
        }
    },
}
</script>