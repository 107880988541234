import { api, apiObject } from "./api";

const URLS = {
    countries:      "countries",
    formValues:     "formValues",
};

export const getCountries = () => {    
    return api.get(`${URLS.countries}`);
};

export const getFormValues = () => {
    return api.get(`${URLS.formValues}`);
};