import RouterView from "@/views/RouterView.vue";
import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";

export default [
    {
        path: "/public-profile",
        name: "",
        components: {
            default: RouterView,
            navbar: TheNavigation,
            footer: TheFooter,
        },  

        children: [
            {
                path: ":id?",
                name: "PublicProfile",
                component: () =>
                    import(
                        /* webpackChunkName: "UserProfile" */ "../views/PublicProfile/PublicProfile.vue"
                    ),
                meta: {
                    requiresAuth: true,
                    title: "Public Profile",
                },
                props: true,
            },
        ],
    },
];
