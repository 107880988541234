import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";
import DihReviews from "../views/adminPages/DihReviews.vue";
import store from "../store/index";
import RouterView from "../views/RouterView.vue"

export default [

    {
        path: "/users",
        name: "Edit users",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "EditUsers" */ "../views/adminPages/EditUsers.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },

        meta: {
            requiresAuth: true,
            title: 'Edit Users'
        },

        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/dih",
        name: "Dih",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Dih" */ "../views/adminPages/Dih.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'DIH Listings'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/create-edit-dih/:id?",
        name: "CreateEditDih",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "CreateEditDih" */ "../views/adminPages/CreateEditDih.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
        },
        beforeEnter:((to, from, next) => {
            if(to.params.id) {
                document.title = `Edit Listing - ${to.params.id} | AgROBOfood`;
            } else {
                document.title = 'Create Listing | AgROBOfood';
            }
            next();
        }),
    },

    {
        path: "/dih-reviews/:id",
        name: "DihReviews",
        components: {
            default: DihReviews,
            navbar: TheNavigation,
            footer: TheFooter,
        },
        props: {
            navbar: false,
            default: true,
        },
        meta: {
            requiresAuth: true,
            title: 'DIH Reviews'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/sme",
        name: "SME",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "SME" */ "../views/adminPages/SME.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'SME Listings'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/create-edit-sme/:id?",
        name: "CreateEditSme",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "SME" */ "../views/adminPages/CreateEditSME.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
        },
        beforeEnter:((to, from, next) => {
            if(to.params.id) {
                document.title = `Edit Listing - ${to.params.id} | AgROBOfood`;
            } else {
                document.title = 'Create Listing | AgROBOfood';
            }
            next();
        }),
    },

    {
        path: "/news",
        name: "News",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "News" */ "../views/adminPages/News.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'News'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/events",
        name: "Events",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Events" */ "../views/adminPages/Events.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Events'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/create-edit-news/:id?",
        name: "CreateEditNews",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "news" */ "../views/adminPages/CreateEditNews.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        props: { default: true },
        meta: {
            requiresAuth: true,
            title: 'Create - Edit News'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/innovation-demonstrators",
        name: "Demonstrators",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Demonstrators" */ "../views/adminPages/Demonstrators.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Demonstrators'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/create-edit-demonstrator/:id?",
        name: "CreateEditDemonstrator",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Demonstrators" */ "../views/adminPages/CreateEditDemonstrator.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Create - Edit Demonstrator'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/newsletter-subscriptions",
        name: "NewsletterSubscriptions",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Newsletter" */ "../views/adminPages/NewsletterSubscriptions.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Newsletter'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                next();
            } else {
                next("/");
            }
        },
    },

    {
        path: "/newsletter",
        name: "",
        components: {
            default: RouterView,
            navbar: TheNavigation,
            footer: TheFooter,
        },
        children: [
            {
                path: "",
                name: "NewsletterList",
                component:  () =>
                        import(
                            /* webpackChunkName: "NewsletterList" */ "../views/adminPages/WebsiteNewsletter/NewsletterList.vue"
                        ),
                
            },
            {
                path: "/create",
                name: "NewsletterCreate",
                component:  () =>
                        import(
                            /* webpackChunkName: "NewsletterList" */ "../views/adminPages/WebsiteNewsletter/NewsletterCreate.vue"
                        ),
                
            },
            {
                path: ":id/edit",
                name: "NewsletterUpdate",
                component:  () =>
                        import(
                            /* webpackChunkName: "NewsletterList" */ "../views/adminPages/WebsiteNewsletter/NewsletterEdit.vue"
                        ),
                props: true
                
            }
        ]
    },

    {
        path: "/toolbox",
        name: "Toolbox",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Toolbox" */ "../views/adminPages/ToolBox.vue"
                ),
                navbar: TheNavigation,
                footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Toolbox'
        }
    },

    {
        path: "/create-edit-toolbox/:id?",
        name: "Create - Edit Toolbox",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "Create - Edit Toolbox" */ "../views/adminPages/CreateEditToolBox.vue"
                ),
                navbar: TheNavigation,
                footer: TheFooter,
        },
        props: {
            default: true,
        },
        beforeEnter:((to, from, next) => {
            if(to.params.id) {
                document.title = `Edit Toolbox - ${to.params.id} | AgROBOfood`;
            } else {
                document.title = 'Create Toolbox | AgROBOfood';
            }
            next();
        }),
    },

    {
        path: "/toolbox-card-details/:id",
        name: "ToolBox Card Details",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "ToolBox Card Details" */ "../views/adminPages/ToolBoxCardDetails.vue"
                ),
                navbar: TheNavigation,
                footer: TheFooter,
        },
        props: {
            default: true,
        },
        meta: {
            requiresAuth: true,
        },
        beforeEnter:((to, from, next) => {
            if(to.params.id) {
                document.title = `ToolBox Card Details - ${to.params.id} | AgROBOfood`;
            }
            next();
        })
    },
    
]