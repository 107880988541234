export const CONFIG = {
    forum_url:                  process.env.VUE_APP_FORUM_URL,
    website_url:                process.env.VUE_APP_WEBSITE_URL,
    main_api:                   process.env.VUE_APP_API_URL,
    test_api:                   process.env.VUE_APP_API_URL_LOCAL,
    locale:                     process.env.VUE_APP_I18N_LOCALE,
    fallback_locale:            process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    map_center:                 [52.519325, 13.392709],
    map_zoom:                   4,
};

export default CONFIG;