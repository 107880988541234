<template>
<v-footer
    app
    dark
    absolute
    padless
    class="footer-position"
  >
    <v-card
      class="flex"
    >
      <div class="py-3 footer-background d-flex justify-center flex-wrap">
        <v-btn
          v-for="(item, index) in determineFooterMenu"
          :key="index"
          :to="item.path"
          outlined
          style="border: none;"
          color="black"
          class="menu_items_hover"
        >
         <v-icon
          left
          x-small
         >
           {{ item.icon }}
         </v-icon>
          {{ item.name }}
        </v-btn>
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-btn
         color="black"
         outlined
         style="border: none;"
         @click="privacyTerms()"
        >
          <v-icon
           left
           x-small
           color="purple"
          >
           fa fa-lock
          </v-icon>
          Privacy Policy
        </v-btn>
        <v-btn
         v-if="!userIsAdmin"
         color="black"
         outlined
         style="border: none;"
         @click="membershipTerms()"
        >
          <v-icon
           left
           x-small
          >
          fa fa-users
          </v-icon>
          Membership Terms
        </v-btn>
        <v-btn
         v-if="userIsAdmin"
         color="black"
         outlined
         style="border: none;"
         :href="forumURL"
        >
          <v-icon
           left
           x-small
          >
           fa fa-comments
          </v-icon>
          Forum
        </v-btn>
      </div>
        <div class="divider-container mx-auto" />
      <v-card-text class="py-3 white--text text-center bottom-background">
        Copyright <span class="purple--text">&copy;</span> {{ calculateCurrentYear }} — <strong>agROBOfood</strong> — All Rights Reserved
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { CONFIG } from "@/common/config";
import { mapGetters } from 'vuex'

export default {
    name: "NavigationFooter",
    data() {
        return {
          forumURL: CONFIG.forum_url,
          usersFooterMenu: [
               {
                name: "Home",
                path: "/home",
                icon: "fa fa-home"
               },
               {
                name: "Oportunities",
                path: "/networking-opportunities",
                icon: "fa fa-diagram-project"
               },
               {
                name: "My Profile",
                path: "/profile",
                icon: "fa fa-user"
               },
            ],
          adminFooterMenu: [
              {
                name: "Users",
                path: "/users",
                icon: "fa fa-users"
              },
              {
                name: "Toolbox",
                path: "/toolbox",
                icon: "fa fa-toolbox"
              },
              {
                name: 'Newsletter',
                path: "/newsletter-subscriptions",
                icon: "fa fa-newspaper"
              },
          ],
        }
    },
    computed: {
        calculateCurrentYear() {
            return new Date().getFullYear();
        },
        ...mapGetters({
            determineUser: "auth/getUserRole",
            userIsAdmin: "auth/userIsAdmin",
        }),
        determineFooterMenu() {
          return this.userIsAdmin ? this.adminFooterMenu : this.usersFooterMenu;
        }
    },
    methods: {
      privacyTerms() {
        this.$router.push({ name: "PrivacyTerms"})
      },
      membershipTerms() {
        if(this.determineUser == 1) {
          // DIH
          this.$router.push({ name: "DihTerms"})
        }
        if(this.determineUser == 2) {
          // SME
          this.$router.push({ name: "SmeTerms"})
        }
      }
    }
}
</script>

<style scoped>
.divider-container {
    width: 500px;
    margin-top: -1px;
    border: 1px solid #C35272;
}
.footer-position {
  width: 100% !important;
  overflow: hidden !important;
}
.menu_items_hover:hover {
  color: #C35272 !important;
  transition: .3s ease-in;
}
.footer-background {
  background: #cfe7cd !important;
    /* background: linear-gradient(to right, #CFE7CD, #fff, #CFE7CD) !important; */
}
.bottom-background {
  background: #3b4c55;
}
</style>