// Component Imports
// import TheNavbar from "../components/base/TheNavbar.vue";
import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";
import Home from "../views/Home.vue";
import MaturityTool from "../views/MaturityTool.vue";
import NotFound from "../views/NotFound.vue";
import DihReviews from "../views/adminPages/DihReviews.vue";

import RouterView from "../views/RouterView.vue";

//Store Import
import store from "../store/index";

/**Other route modules */
import networkingOpportunities from "./networkingOpportunities";
import profileRoutes from "./profile";
import publicProfileRoutes from "./public-profile";
import privacyTerms from "./privacyTerms";
import smeRoutes from "./smeRoutes";
import loginRoutes from "./loginRoutes";
import adminRoutes from "./adminRoutes";

const routes = [
    {
        path: "/",
        name: "Home",
        redirect: (to) => {
            return { path: "/home" };
        },
        components: {
            default: Home,
            navbar: TheNavigation,
            footer: TheFooter,
        },

        meta: {
            requiresAuth: true,
            title: "Home",
        },
    },

    {
        path: "/maturity-tool",
        name: "Maturity tool",
        components: {
            default: MaturityTool,
            navbar: TheNavigation,
            footer: TheFooter,
        },

        meta: {
            requiresAuth: true,
            title: "Maturity Tool",
        },
    },
    {
        path: "/my-listings",
        name: "MyListings",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "MyListings" */ "../views/MyListings.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },

        meta: {
            requiresAuth: true,
            title: "My Listings",
        },
    },

    {
        path: "/search/:results",
        name: "Search",
        components: {
            default: () =>
                import(/* webpackChunkName: "Search" */ "../views/Search.vue"),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        params: true,
        props: {
            default: true,
        },
        meta: {
            title: "Search",
        },
    },

    {
        path: "/home",
        name: "HomePage",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "HomePage" */ "../views/HomePage.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: "Home",
        },
        beforeEnter: (to, from, next) => {
            if (store.getters["auth/userIsAdmin"]) {
                return next({ path: "/users" });
            }
            return next();
        },
    },

    profileRoutes,
    ...publicProfileRoutes,
    ...networkingOpportunities,
    ...privacyTerms,
    ...smeRoutes,
    ...loginRoutes,
    ...adminRoutes,

    /**Not Found Page catching all URLS after every other check fails */

    {
        path: "/not-found",
        name: "Not found",
        components: {
            default: NotFound,
        },
        meta: {
            title: "Not Found",
        },
        alias: "/*",
    },
];

export default routes;
