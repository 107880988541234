<template>
    <v-container>
        <p class="text-body-1 font-weight-black">
            {{ $t("RegisterForm.OrgName.title") }}
        </p>
        <v-text-field
            v-model.trim="inputName"
            :error-messages="fullNameErrors"
            type="text"
            dense
            outlined
            required
            :label="$t('RegisterForm.OrgName.fullNameLabel')"
            class="my-3"
            @input="updateFullName($event)"
            @blur="$v.inputName.$touch()"
        ></v-text-field>

        <v-text-field
            v-model="orgWebsite"
            required
            dense
            outlined
            type="text"
            :error-messages="orgWebsiteErrors"
            :label="$t('RegisterForm.OrgName.orgWebsite')"
            class="my-3"
            @blur="$v.orgWebsite.$touch()"
            @input="updateOrgWebsite($event)"
        ></v-text-field>

        <v-text-field
            v-model="skillsWebsite"
            type="text"
            dense
            outlined
            :label="$t('RegisterForm.OrgName.skillsWebsite')"
            class="my-3"
            @input="updateSkillsWebsite($event)"
        ></v-text-field>

       <div v-if="determineUser == 1">
         <v-text-field
            v-model="smartWebsite"
            type="text"
            dense
            outlined
            :label="$t('RegisterForm.OrgName.smartWebsite')"
            class="my-3"
            @input="updateSmartWebsite($event)"
        ></v-text-field>
       </div>

        <country
            :country.sync="inputCountry"
            :form-valid.sync="countryIsValid"
        ></country>
    </v-container>
</template>

<script>
//Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Country from "./Country.vue";

export default {
    name: "OrganizationName",

    mixins: [validationMixin],

    validations: {
        inputName: {
            required,
        },

        orgWebsite: {
        },
    },

    props: {
        selectedUser: {
            type: [String, Number],
            required: true,
        },
        name: {
            type: String,
            required: true,
        },

        website: {
            type: String,
            required: true,
        },

        orgSkillsWebsite: {
            type: String,
            required: true,
        },

        orgSmartWebsite: {
            type: String,
            required: true,
        },

        country: {
            type: Number,
            required: true,
        },

        formValid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: { Country },

    data() {
        return {
            inputName: this.name || "",
            orgWebsite: "https://",
            smartWebsite: "https://",
            skillsWebsite: "https://",

            inputCountry: null,
            countryIsValid: false,
            determineUser: null,
        };
    },

    /**
     * Computed functions used to validate inputs with vuelidate.
     * @return {errors} return errors that are shown on blur and on input
     */

    computed: {
        fullNameErrors() {
            const errors = [];
            if (!this.$v.inputName.$dirty) return errors;
            !this.$v.inputName.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        orgWebsiteErrors() {
            const errors = [];
            if (!this.$v.orgWebsite.$dirty) return errors;
            // !this.$v.orgWebsite.required &&
            //     errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formIsValid() {
            return !this.$v.$invalid && !!this.countryIsValid;
        },
    },

    /**
     * Update methods that are used to update props (using .sync in the parent).
     * Updating formValid is for updating the given array's item that handles the next button.
     */

    methods: {
        updateFullName(e) {
            this.$v.inputName.$touch();
            this.$emit("update:name", e);
            this.updateFormValid();
        },

        updateOrgWebsite(e) {
            this.$v.orgWebsite.$touch();
            this.$emit("update:website", e);
            this.updateFormValid();
        },

        updateSkillsWebsite(e) {
            this.$emit("update:orgSkillsWebsite", e);
        },

        updateSmartWebsite(e) {
            this.$emit("update:orgSmartWebsite", e);
        },

        updateFormValid() {
            this.$emit("update:formValid", this.formIsValid);
        },
    },

    /**
     * These wathcers are used to update the checked information
     * in case admin wants to see and change them
     */

    watch: {
        name(newVal) {
            if (newVal) this.inputName = newVal;
        },

        inputCountry(newVal) {
            this.$emit("update:country", newVal);
            this.$emit("update:formValid", this.formIsValid);
        },
        selectedUser(newVal) {
            this.determineUser = newVal;
        }
    },
};
</script>
