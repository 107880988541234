<template>
    <v-container>
        <p class="text-body-1 font-weight-black">
            {{ $t("RegisterForm.ContactPerson.title") }}
        </p>
        <p class="text-body-2">
            {{ $t("RegisterForm.ContactPerson.sub") }}
        </p>
        <v-text-field
            v-model.trim="inputFullName"
            :error-messages="fullNameErrors"
            type="text"
            required
            dense
            outlined
            :label="$t('RegisterForm.ContactPerson.nameLabel')"
            @input="updateFullName($event)"
            @blur="$v.inputFullName.$touch()"
        ></v-text-field>

        <v-text-field
            v-model.trim="inputEmail"
            :error-messages="emailErrors"
            required
            dense
            outlined
            :label="$t('RegisterForm.ContactPerson.emailLabel')"
            @input="updateEmail($event)"
            @blur="$v.inputEmail.$touch()"
        ></v-text-field>

        <v-text-field
            v-model.trim="inputSecondEmail"
            :error-messages="secondaryEmailErrors"
            dense
            outlined
            :label="$t('RegisterForm.ContactPerson.secondaryEmailLabel')"
            @input="updateSecondaryEmail($event)"
            @blur="$v.inputSecondEmail.$touch()"
        ></v-text-field>

        <v-text-field
            v-model.trim="inputPhone"
            :error-messages="phoneErrors"
            type="tel"
            dense
            outlined
            :label="$t('RegisterForm.ContactPerson.phoneLabel')"
            @input="updatePhone($event)"
            @blur="$v.inputSecondEmail.$touch()"
        ></v-text-field>
    </v-container>
</template>

<script>
// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import EmailInput from "@/components/Inputs/EmailInput.vue";

const phoneValidation = (value) => {
    const regex1 = /^\+[0-9]{2}[0-9]{10}$/;
    const regex2 = /^[0-9]{4}[0-9]{10}$/;

    return regex1.test(value) || regex2.test(value) || value === ""
}

export default {
    name: "ContactPerson",

    mixins: [validationMixin],

    validations: {
        inputFullName: {
            required,
        },

        inputEmail: {
            required,
            email,
        },

        inputSecondEmail: {
            email,
        },

        inputPhone: {
            phoneValidation,
        },
    },

    props: {
        fullName: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        secondaryEmail: {
            type: String,
            required: true,
        },

        phone: {
            type: String,
            required: true,
        },

        formValid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: { EmailInput },

    data() {
        return {
            inputFullName: this.fullName,
            inputEmail: this.email,
            inputSecondEmail: this.secondaryEmail,
            inputPhone: this.phone,
        };
    },

    /**
     * Computed functions used to validate inputs with vuelidate.
     * @return {errors} return errors that are shown on blur and on input
     */
    computed: {
        fullNameErrors() {
            const errors = [];
            if (!this.$v.inputFullName.$dirty) return errors;
            !this.$v.inputFullName.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.inputEmail.$dirty) return errors;
            !this.$v.inputEmail.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.inputEmail.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            return errors;
        },

        secondaryEmailErrors() {
            const errors = [];
            if (!this.$v.inputSecondEmail.$dirty) return errors;

            !this.$v.inputSecondEmail.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            return errors;
        },

        phoneErrors() {
            const errors = [];
            if (!this.$v.inputPhone.$dirty) return errors;
            
            !this.$v.inputPhone.phoneValidation &&
                errors.push(this.$t("ErrorMessages.phoneFormat"));
            return errors;
        },

        formIsValid() {
            return !this.$v.$invalid;
        },
    },

    /**
     * Update methods that are used to update props (using .sync in the parent).
     * Updating formValid is for updating the given array's item that handles the next button.
     */

    methods: {
        updateFullName(event) {
            this.$v.inputFullName.$touch();
            this.$emit("update:fullName", event);
            this.updateFormValid();
        },

        updateEmail(event) {
            this.$v.inputEmail.$touch();
            this.$emit("update:email", event);
            this.updateFormValid();
        },
        updateSecondaryEmail(event) {
            this.$v.inputSecondEmail.$touch();
            this.$emit("update:secondaryEmail", event);
            this.updateFormValid();
        },

        updatePhone(event){
            this.$v.inputPhone.$touch();
            this.$emit("update:phone", event);
            this.updateFormValid();
        },

        updateFormValid() {
            this.$emit("update:formValid", this.formIsValid);
        },
    },

    /**
     * These wathcers are used to update the checked information
     * in case admin wants to see and change them
     */

    watch: {
        fullName(newVal) {
            if (newVal) this.inputFullName = newVal;
        },
    },
};
</script>
