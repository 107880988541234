<template>
    <v-container>
            <iframe 
                v-show="!isLoading"
                ref="maturityIframe" 
                :src="iframeSrc"
                style="border: none !important;"
                class="maturity_iframe"
            />
            <div 
                v-show="isLoading"
            >
            <v-skeleton-loader
                type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, actions"
            >
            </v-skeleton-loader>
            <!-- <v-progress-circular
                indeterminate
                color="primary"
                class="mt-10"
            ></v-progress-circular> -->
            </div>
    </v-container>
</template>

<script>
// API layer Imports
import { apiStatus } from "../api/constants/apiStatus"
import { apiStatusComputed } from "../api/helpers/computedApiStatus"
import { withAsync } from "../helpers/withAsync"
import { getMaturityUrl } from "../api/maturityApi.js"
import { mapGetters } from "vuex";

export default {
    name: "MaturityTool",

    data(){
        return {
            iframeSrc: "",
            errorMessage: "",
            isLoading: true,
        }
    },

    computed: {
        ...apiStatusComputed(["getMaturitySourceStatus"]),
        ...mapGetters({
            userID: "auth/getUserId",
            userToken: "auth/getToken"
        })
    },

    methods: {
        /** 
        * Get data with API call
        * @summary make a call to the backend to fetch the maturity tool url
        * Then set it as the iframe's source and make a postMessage to the iframe
        * with the user's token as data
        */
        async setMaturitySource() {
            
        this.getMaturitySourceStatus = apiStatus.Pending;
        
        const { response, error } = await withAsync(getMaturityUrl);

            if(error) {
                this.getMaturitySourceStatus =  apiStatus.Error;
                this.errorMessage = error.response.status === 500 ? error.response.statusText : error.response.data;
                return
            }

            this.iframeSrc = response.data.data.url;
            this.getMaturitySourceStatus = apiStatus.Success;
            
            setTimeout( () => {
                this.sendPostMessage()}
                , 1000);
        },

        /** 
        * Send postMessage to the iframe's content with user's token as data
        * to validate the user and serve the proper page
        */
       
        sendPostMessage(){
            // const token = localStorage.getItem("agrobofood_token");
            // const userId = localStorage.getItem("agrobofood_user_id");

            const maturityFrame = this.$refs.maturityIframe

            // console.log('mat', maturityFrame.contentDocument)

            maturityFrame.contentWindow.postMessage({
                user_token: this.userToken,
                user_id: this.userID,
                }, this.iframeSrc);

            this.isLoading = false;
        },
    },

    created(){
        this.apiStatus = apiStatus;  
         this.setMaturitySource();
    }


}
</script>

<style scoped>

.maturity_iframe{
    position: fixed;
    left: 0;
    top: 75px !important;
    height: 90vh;
    width: 100vw;
    padding: 0;
    margin: 0;
}

.loading__container{
    width: 90vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>