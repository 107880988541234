<template>
    <v-container>
        <p class="text-body-1">
            {{ $t("RegisterForm.registerForm.title") }}
        </p>

        <v-text-field
            v-model="inputEmail"
            :error-messages="emailErrors"
            type="text"
            required
            dense
            outlined
            :label="$t('RegisterForm.registerForm.emailLabel')"
            @input="updateUserEmail($event)"
            @blur="$v.inputEmail.$touch()"
        ></v-text-field>

        <v-text-field
            v-model="inputSecondaryEmail"
            :error-messages="secondaryEmailErrors"
            type="text"
            dense
            outlined
            autofill="noEmailField"
            :label="$t('RegisterForm.registerForm.secondaryRegisterEmailLabel')"
            @input="updateUserSecondaryEmail($event)"
            @blur="$v.inputSecondaryEmail.$touch()"
        ></v-text-field>

        <v-text-field
            v-model.trim="inputPassword"
            :error-messages="passwordErrors"
            :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="showPassword ? '' : 'password'"
            required
            dense
            outlined
            :label="$t('RegisterForm.registerForm.passwordLabel')"
            @input="updatePassword($event)"
            @blur="$v.inputPassword.$touch()"
            @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
            v-model.trim="inputPasswordConfirmation"
            :error-messages="passwordConfirmationErrors"
            :append-icon="showPasswordConf ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="showPasswordConf ? '' : 'password'"
            required
            dense
            outlined
            :label="$t('RegisterForm.registerForm.repeatPasswordLabel')"
            @input="updatePasswordConf($event)"
            @blur="$v.inputPasswordConfirmation.$touch()"
            @click:append="showPasswordConf = !showPasswordConf"
        ></v-text-field>
    </v-container>
</template>

<script>
// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: "RegisterForm",

    mixins: [validationMixin],

    validations: {
        inputEmail: {
            required,
            email,
        },

        inputSecondaryEmail: {
            email,
        },

        inputPassword: {
            required,
            minLengthValue: minLength(3),
        },

        inputPasswordConfirmation: {
            required,
            sameAsPassword: sameAs("inputPassword"),
        },
    },

    props: {
        email: {
            type: String,
            required: true,
        },
        secondaryEmail: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            required: true,
        },
        passwordConfirmation: {
            type: String,
            required: true,
        },
        formValid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            inputEmail: this.email,
            inputSecondaryEmail: this.secondaryEmail,
            inputPassword: this.password,
            inputPasswordConfirmation: this.passwordConfirmation,

            showPassword: false,
            showPasswordConf: false,
        };
    },

    /**
     * Computed functions used to validate inputs with vuelidate.
     * @return {errors} return errors that are shown on blur and on input
     */

    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.inputEmail.$dirty) return errors;
            !this.$v.inputEmail.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.inputEmail.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            return errors;
        },

        secondaryEmailErrors() {
            const errors = [];
            if (!this.$v.inputSecondaryEmail.$dirty) return errors;
            !this.$v.inputSecondaryEmail.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.inputPassword.$dirty) return errors;
            !this.$v.inputPassword.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.inputPassword.minLengthValue &&
                errors.push(this.$tc("ErrorMessages.minLength", 3));
            return errors;
        },

        passwordConfirmationErrors() {
            const errors = [];
            if (!this.$v.inputPasswordConfirmation.$dirty) return errors;
            !this.$v.inputPasswordConfirmation.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.inputPasswordConfirmation.sameAsPassword &&
                errors.push(this.$t("ErrorMessages.samePassword"));
            return errors;
        },

        formIsValid() {
            return !this.$v.$invalid;
        },
    },

    /**
     * Update methods that are used to update props (using .sync in the parent).
     * Updating formValid is for updating the given array's item that handles the next button.
     */

    methods: {
        updateUserEmail(event) {
            this.$emit("update:email", event);
            this.updateFormValid();
        },

        updateUserSecondaryEmail(event) {
            this.$emit("update:secondaryEmail", event);
            this.updateFormValid();
        },
        updatePassword(event) {
            this.$emit("update:password", event);
            this.updateFormValid();
        },
        updatePasswordConf(event) {
            this.$emit("update:passwordConfirmation", event);
            this.updateFormValid();
        },

        updateFormValid() {
            this.$emit("update:formValid", this.formIsValid);
        },
    },
};
</script>
