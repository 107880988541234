<template>
    <div class="register__container">
        <base-dialog :show="showDialog" :title="dialogTitle" :text="dialogText">
            <template #actions>
                <v-btn :color="dialogColor" depressed @click="closeDialog()">
                    {{ $t("General.ok") }}
                </v-btn>
            </template>
        </base-dialog>
        <v-card
            v-if="getFormValuesStatus_Success"
            max-width="800"
            max-height="750"
            class="register_form__container d-flex flex-column"
        >
            <v-img
                src="../../assets/logoagro.png"
                max-height="120"
                max-width="85"
                contain
                alt="agrobofood logo"
                class="register_form__logo"
            >
            </v-img>
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <p
                            class="register_span text-center text-body-1 text-md-h6 purple--text"
                        >
                            {{ $t("Register.title") }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-stepper
                    v-model="step"
                    color="primary"
                    class="elevation-0 px-md-10 stepper__container"
                    max-height="500"
                >
                    <v-stepper-items>
                        <v-stepper-content step="1">
                        <div class="login_form_info">
                            <p>
                                <span class="primary--text login_span">Welcome to agROBOfood network!</span>
                                <br>
                                <br>
                                Digital Innovation Hub(s) (Associated Members) and SMEs, Companies (Business Members) can become part of the agROBOfood network in a few easy steps by filling out our questionnaire!
                                <br>
                                <br>
                                <strong>As an Associated Member of agROBOfood network you can leverage:</strong>
                                <br>
                                i) Collaborative Networks: Partner with industry leaders, researchers, and other hubs;
                                <br>
                                ii) Access to Technology: Stay updated with cutting-edge agricultural innovations; 
                                <br>
                                iii) Funding and Support: Obtain financial backing for your projects;
                                <br>
                                iv) Expertise Sharing: Gain and share valuable knowledge;
                                <br>
                                v) Visibility: Enhance your profile and credibility in the sector;
                                <br>
                                vi) Market Expansion: Explore new markets and segments;
                                <br>
                                vii) Training: Access workshops and development programs;
                                <br>
                                viii) Innovation Ecosystem: Be part of a dynamic, supportive community driving agricultural innovation.
                                <br>
                                <br>
                                <strong>As a Business Member of agROBOfood network you can leverage:</strong>
                                <br>
                                i) Independent innovation mentoring via our network of DIHs and CCs;
                                <br>
                                ii) Connection to EU-level Testing and Experimentation Facilities (TEFs); Participation in matchmaking for market and business model analysis through our network of DIHs and CCs;
                                <br>
                                iii) Access to local one-stop-shops in your region to connect with farming organizations, cooperatives, and farmers interested in digital agri-food solutions;
                                <br>
                                iv) Listing of your organization in our business directory;
                                <br>
                                v) Participation in our "Pitch Your Robot, Find Your Investor" event, including pitch training;
                                <br>
                                vi) Access to listings of public and private funding opportunities through our website; and
                                <br>
                                vii) Access to listings of training programs provided by DIHs and CCs in our network.
                                <br>
                                <br>
                                See below the access you have to agROBOfood services either as an associated or a business member: 
                            </p>
                            <!-- First -->
                            <br>
                            <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Skills & Training services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in skills"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Second -->
                            <br>
                            <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Ecosystem networking​ Services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in ecosystem"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Third -->
                             <br>
                             <br>
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Test before invest services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in testBeforeInvest"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <!-- Fourth -->
                             <br>
                             <br>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left" style="width: 300px">
                                        Support to find investments services
                                    </th>
                                    <th class="text-left">
                                        Associated Member
                                    </th>
                                    <th class="text-left">
                                        Business Member
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in supportInvestments"
                                    :key="item.name"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.associated }}</td>
                                    <td>{{ item.business }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-stepper-content>
                        <v-stepper-content step="2">
                            <register-welcome></register-welcome>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <organization-type
                                :org-type.sync="userInfo.org_type"
                                :form-valid.sync="handleNextArray[1]"
                            ></organization-type>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                            <organization-name
                                v-bind.sync="userInfo.org_info"
                                :selected-user="userInfo.org_type"
                                :form-valid.sync="handleNextArray[2]"
                            >
                            </organization-name>
                        </v-stepper-content>

                        <v-stepper-content step="5">
                            <contact-person
                                v-bind.sync="userInfo.contact_person"
                                :form-valid.sync="handleNextArray[3]"
                            >
                            </contact-person>
                        </v-stepper-content>

                        <v-stepper-content step="6">
                            <services-provided
                                v-bind.sync="userInfo.servicesProvided"
                                :form-valid.sync="handleNextArray[4]"
                                :user-type="userInfo.org_type"
                            ></services-provided>
                        </v-stepper-content>

                        <v-stepper-content step="7">
                            <terms-and-conditions
                                :terms-conditions.sync="
                                    userInfo.terms_and_conditions
                                "
                                :user-type="userInfo.org_type"
                                :form-valid.sync="handleNextArray[5]"
                            >
                            </terms-and-conditions>
                        </v-stepper-content>

                        <v-stepper-content step="8">
                            <register-form
                                v-bind.sync="userInfo.user_credentials"
                                :form-valid.sync="registerIsValid"
                            >
                            </register-form>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>

            <v-card-actions class="mt-auto">
                <v-row
                    justify="start"
                    align-content="start"
                    class="pr-3 px-md-10"
                >
                    <v-col cols="4" align-self="center" class="text-left">
                        <v-btn
                            v-show="step > 1"
                            color="primary"
                            depressed
                            class="submit-button-hover"
                            @click="handleBack()"
                        >
                            {{ $t("General.back") }}
                        </v-btn>
                    </v-col>

                    <v-col cols="4">
                        <p v-if="step === 2">
                            <router-link to="/login">
                                <p
                                    class="text-caption purple--text text-center"
                                >
                                    {{ $t("Register.alreadyHas") }}
                                    {{ " " + $t("Login.fields.loginBtn") }}
                                </p>
                            </router-link>
                        </p>
                        <p v-else-if="step !== 1" class="text-center my-2">
                            {{ step - 2 }} / {{ maxStep - 2 }}
                        </p>
                    </v-col>

                    <v-col
                        v-if="getFormValuesStatus_Success"
                        cols="4"
                        class="text-right"
                    >
                        <v-btn
                            v-show="step < maxStep"
                            color="primary"
                            depressed
                            class="submit-button-hover"
                            :disabled="!handleNextArray[step - 1]"
                            @click="handleNextStep()"
                        >
                            {{ $t("General.next") }}
                        </v-btn>
                        <v-btn
                            v-show="step === maxStep"
                            color="primary"
                            class="submit-button-hover"
                            depressed
                            :loading="registerStatus_Pending"
                            :disabled="!registerIsValid"
                            @click="register()"
                        >
                            {{ $t("Register.fields.registerBtn") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <p v-else class="text-center">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { getFormValues } from "../../api/formValuesApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { registerUser } from "../../api/authApi.js";

// Component Imports
import BaseDialog from "../../components/base/BaseDialog.vue";
import OrganizationType from "../../components/registerForm/OrganizationType.vue";
import OrganizationName from "../../components/registerForm/OrganizationInfo.vue";
import Country from "../../components/registerForm/Country.vue";
import ContactPerson from "../../components/registerForm/ContactPerson.vue";
import ServicesProvided from "../../components/registerForm/ServicesProvided.vue";
import TermsAndConditions from "../../components/registerForm/termsAndConditions.vue";
import RegisterForm from "../../components/registerForm/RegisterForm.vue";
import RegisterWelcome from "../../components/registerForm/RegisterWelcome.vue";

export default {
    name: "Register",

    components: {
        OrganizationType,
        OrganizationName,
        Country,
        ContactPerson,
        ServicesProvided,
        TermsAndConditions,
        RegisterForm,
        RegisterWelcome,
        BaseDialog,
    },
    /**
     *          {handleNextArray}
     * Note: used to handle whether the next button
     * shall be shown for each step of the wizard.
     * Values change with .sync in each component that composes the wizard
     * Every step uses the respective array index - 1 (Steps start at 1 while array at 0)
     */

    data() {
        return {
            getFormValuesStatus: apiStatus.Idle,
            registerStatus: apiStatus.Idle,
            skills: [
                {
                    name: "Maturity tool",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Service box",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Ethics awareness",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Digital skills",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Inonovation skills",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Regulatory awareness",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            ecosystem: [
                {
                    name: "DIH Directory",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Forum",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Matchmaking",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Newsletter",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Events",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            testBeforeInvest: [
                {
                    name: "Technical trainings and (interegional) demos​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Standardisation needs​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Technical Mentoring services​​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Mapping of robotics technologies in agri-food​",
                    associated: "✔️",
                    business: "✔️",
                },
                {
                    name: "Best Practices in Developing Open Platforms",
                    associated: "✔️",
                    business: "✔️",
                }
            ],
            supportInvestments: [
                {
                    name: "agROBOfood sustainability plan",
                    associated: "✔️",
                    business: "",
                },
                {
                    name: "Business Member Directory and Portal",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Business Mentoring services",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Pitch your robot event",
                    associated: "",
                    business: "✔️",
                },
                {
                    name: "Connection to funding sources",
                    associated: "",
                    business: "✔️",
                }
            ],
            step: 1,
            showNext: true,
            handleNextArray: [true, false, false, false, false, false],
            registerIsValid: false,

            userInfo: {
                org_type: 0,
                org_info: {
                    name: "",
                    website: "",
                    orgSkillsWebsite: "",
                    orgSmartWebsite: "",
                    country: 0,
                },

                contact_person: {
                    fullName: "",
                    email: "",
                    secondaryEmail: "",
                    phone: "",
                },

                terms_and_conditions: false,
                user_credentials: {
                    email: "",
                    secondaryEmail: "",
                    password: "",
                    passwordConfirmation: "",
                },

                servicesProvided: {
                    test: [],
                    skills: [],
                    ecosystem: [],
                    support: [],
                    technologyFields: [],
                    innovationFields: [],
                },
            },

            showDialog: false,
            dialogText: "",
            dialogTitle: "",
            errorMessage: "",
        };
    },

    computed: {
        ...apiStatusComputed(["registerStatus", "getFormValuesStatus"]),

        dialogColor() {
            return this.registerStatus_Success ? "primary" : "error";
        },

        maxStep() {
            return 7;
        },
    },

    methods: {
        /**
         * The 2 following functions are handling the next and back button press.
         * They use the handleNextArray to show or not the next button
         */

        handleNextStep() {
            this.step = this.step + 1;
            this.showNext = this.handleNextArray[this.step - 1];
        },

        handleBack() {
            this.step = this.step - 1;
            this.showNext = this.handleNextArray[this.step - 1];
        },

        /**
         * Sends a call to the backend holding the needed data as payload. Returns success or unsuccessful
         * dialog based on the backend response for registering the user
         */

        async register() {
            this.registerStatus = apiStatus.Pending;

            const payload = this.createPayload();
            const { response, error } = await withAsync(registerUser, payload);

            if (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    this.dialogText = Object.values(errors)[0][0];
                } else {
                    this.dialogText = error.response.data.message;
                }
                this.registerStatus = apiStatus.Error;
                this.dialogTitle = this.$t("Overlay.oops");

                this.showDialog = true;
                return;
            }

            this.registerStatus = apiStatus.Success;

            this.dialogTitle = this.$t("Register.successDialogTitle");
            this.dialogText = this.$t("Register.successDialogMessage");
            this.showDialog = true;
        },

        /**
         * Getting the form values (e.g. countries, selects)
         * so we can create all the stepper steps on create.
         */

        async fetchFormValues() {
            this.getFormValuesStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getFormValues);

            if (error) {
                this.getFormValuesStatus = apiStatus.Error;
                this.errorMessage =
                    error.response.status === 500
                        ? error.response.statusText
                        : error.response.data;
                return;
            }

            this.$store.dispatch(
                "formValues/setFormValues",
                response.data.data
            );
            this.getFormValuesStatus = apiStatus.Success;
        },

        closeDialog() {
            this.showDialog = false;

            if (this.registerStatus_Error) return;
            this.$router.push("/login");
        },

        createPayload() {
            const org_info = this.userInfo.org_info;
            const contact = this.userInfo.contact_person;
            const creds = this.userInfo.user_credentials;
            const services = this.userInfo.servicesProvided;

            let payload = {
                user_type: this.userInfo.org_type,
                organization_fullname: org_info.name,
                organization_web_url: org_info.website,
                skills_training_web_url: org_info.orgSkillsWebsite,
                smart_specialisation_web_url: org_info.orgSmartWebsite,
                country: org_info.country,

                contact_fullname: contact.fullName,
                contact_email: [contact.email, contact.secondaryEmail],
                contact_phone: contact.phone,
                terms_and_conditions: this.userInfo.terms_and_conditions,

                email: creds.email,
                secondary_email: creds.secondaryEmail == '' || null ? null : creds.secondaryEmail,
                password: creds.password,
                password_confirmation: creds.passwordConfirmation,

                test_before_invest: [...services.test],
                skills_training: [...services.skills],
                ecosystem_networking: [...services.ecosystem],
                support_to_find_investments: [...services.support],
                technology_fields: [...services.technologyFields],
                innovation_fields: [...services.innovationFields],
            };

            return payload;
        },
    },
    created() {
        this.apiStatus = apiStatus;
        this.fetchFormValues();
    },
};
</script>

<style scoped>
.stepper__container {
    overflow-y: auto;
}

.register__container {
    width: 100%;
    min-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
}

.register_form__container {
    border-radius: 10px;
    width: 80vw;
    margin: 60px 50px !important;
    height: 100%;
    min-height: 600px;
}

.register_span {
    font-size: 1.3rem;
}

.register_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register_form__logo {
    position: absolute;
    transform: translate(-50px, -50px);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.v-stepper__content {
    padding: 0;
}
.register__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}

.login_span {
    font-size: 1.3rem;
    transform: translateY(-30px);
}

.register__container {
    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    position: absolute;
    animation: animateBackground 10s ease infinite;
}

.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
