import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import store from "../store/index";


export default [

    {
        path: "/login",
        name: "Login",
        component: Login,

        beforeEnter: (to, from, next) => {
            if (store.getters["auth/isLogged"]) {
                next("/");
            } else {
                next();
            }
        },
        meta: {
            title: 'Login'
        }
    },

    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            title: 'Register'
        }
    },

    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () =>
            import(
                /* webpackChunkName: "ForgotPassword" */ "../views/auth/ForgotPassword.vue"
            ),
            meta: {
                title: 'Forgot Password'
            }
    },

    {
        path: "/reset-password",
        name: "Reset password",
        component: () =>
            import(
                /* webpackChunkName: "ResetPassword" */ "../views/auth/ResetPassword.vue"
            ),
            meta: {
                title: 'Reset Password'
            }
    },

    {
        path: "/verify",
        name: "Verify account",
        component: () =>
            import(
                /* webpackChunkName: "VerifyAccount" */ "../views/auth/VerifyAccount.vue"
            ),
            meta: {
                title: 'Verify Account'
            }
    },

]