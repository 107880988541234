<template>
    <v-menu
     v-model="menu"
     :close-on-content-click="false"
     absolute
     :position-y="100"
     transition="slide-y-transition"
    >
        <template v-slot:activator="{ on, attrs }">
         <v-btn
             color="black"
             outlined
             height="40"
             depressed
             fab
             width="40"
             style="border: none;"
             class="primary--text search-button"
             v-bind="attrs"
             v-on="on"
            >
              <v-icon
               small
               color="black"
              >
                fa-thin fa-magnifying-glass
              </v-icon>
            </v-btn>
        </template>
        <v-list
        dense
        flat
         class="pa-5 d-flex flex-column justify-center"
        >
        <p class="purple--text">
            Search something here..
        </p>
          <v-text-field
            v-model="searchResults"
            label="Type here.."
            outlined
            required
            :error-messages="inputValueErrors"
            @keyup.enter="submitSearch()"
            @input="
              $v.searchResults.$touch();
              emit();
            "
            @blur="
              $v.searchResults.$touch();
              emit();
            "
          >
          </v-text-field>
          <v-btn
           color="primary" 
           depressed
           class="search-button-inner"
           @click="submitSearch()"
          >
           Search
          </v-btn>
        </v-list>
    </v-menu>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
   name: "SearchBox",
   validations: {
      searchResults: {
        required,
      }
   },
   data() {
    return {
        menu: false,
        searchResults: '',
    }
   },
   computed: {
    inputValueErrors() {
      const errors = [];
      if (!this.$v.searchResults.$dirty) return errors;
      !this.$v.searchResults.required && errors.push('Must not be empty');
      return errors;
    },
   },
   methods: {
    emit() {
      this.$emit("update:in-valid", this.$v.invalid);
    },
    submitSearch() {
        this.menu = !this.menu;
        if (this.searchResults != '') {
           this.$router.push({ name: "Search", params: { results: this.searchResults } })
           this.searchResults = '';
        } 
        else {
          this.menu = true;
        }
       
    }
   }
}
</script>

<style scoped>
.search-button {
    overflow: hidden !important;
}
.search-button:hover {
    background: #eee !important;
    transition-duration: 0.3s ease-in-out;
}
.search-button-inner:hover {
  background: #C35272 !important;
  transition: 0.3s ease-in;
}
</style>