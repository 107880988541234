import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    formValues:{
        organizations:      [],
        tech_services:      [],
        business_services:  [],
        ecosystem_services: [],
        training_services:  [],
        agrifood_sectors:   [],
        data_collection:    [],
        analytics:          [],
        automation:         [],
        technology_fields:  [],
        innovation_fields:  [],
    },                 
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}