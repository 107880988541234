<template>
    <v-container class="fill-height d-flex flex-column align-center justify-center">
        <div class="agro_tear__container">
            <img
                src="../assets/logoagro.png"
                class="logo_agro"
                alt="agrobofood logo"
            >
            <img
                src="../assets/tear.png"
                height="40"
                width="20"
                class="agro_tear"
                alt="agrobofood tear"
            >
        </div>
        <v-container class="not_found__text">
            <h1 class="error--text font-weight-thin">404</h1>
            <h3 class="black--text mb-10">{{ $t("NotFound.title") }}</h3>
            <v-btn
                depressed
                color="primary"
                large
                class="mt-5 submit-button-hover"
                @click="returnHome()"
            >
                {{ $t("NotFound.backBtn") }}
            </v-btn>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "NotFoundPage",

    computed: {
        ...mapGetters({
            userIsLogged: "auth/isLogged",
            userIsAdmin: "auth/userIsAdmin",
            determineUser: "auth/getUserRole",
        }),
    },

    methods: {
        returnHome() {
            if (this.userIsAdmin) {
                this.$router.push("/users");
            } else {
                this.$router.push("/");
            }
        },
    },
};
</script>

<style scoped>
.agro_tear__container {
    transition: all 1.3s ease-out;
    margin-right: 30px;
    margin-bottom: 20px;
    position: relative;
}

.logo_agro {
    height: 18rem;
}

.agro_tear {
    position: absolute;
    transform: translate(-180px, 60px);
    animation: tear_drop 1.3s infinite;
}

.not_found__text {
    text-align: center;
}

.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}

@keyframes tear_drop {
    10% {
        opacity: 1;
    }
    75% {
        opacity: 0.7;
    }
    95% {
        opacity: 0;
        transform: translate(-180px, 90px);
    }

    100% {
        opacity: 0;
        transform: translate(-180px, 90px);
    }
}
</style>
