<template>
    <div>
        <v-row>
            <v-col
                v-for="(formValue, key, idx) in computedShownFields"
                :key="idx"
                cols="12"
                class="pb-1 pt-2"
            >
                <select-input
                    :input-value.sync="formValue.value"
                    :current-value="formValue.value"
                    :items="getItems(formValue.items)"
                    :multiple="true"
                    :required-input="false"
                    :label="$t(`Register.fields.${key}`)"
                    class="pt-2"
                    @update:input-value="updateInput($event, key)"
                ></select-input>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SelectInput from "../Inputs/SelectInput.vue";
import { mapGetters } from "vuex";
export default {
    name: "ServicesProvided",

    props: {
        ecosystem: {
            type: Array,
            required: false,
            default: () => [],
        },
        test: {
            type: Array,
            required: false,
            default: () => [],
        },
        skills: {
            type: Array,
            required: false,
            default: () => [],
        },
        support: {
            type: Array,
            required: false,
            default: () => [],
        },
        technologyFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        innovationFields: {
            type: Array,
            required: false,
            default: () => [],
        },

        userType: {
            type: Number,
            required: true,
        },
    },
    components: { SelectInput },
    data() {
        return {
            inputs: {
                ecosystem: {
                    value: [],
                    inValid: true,
                    items: "paramsEcosystem",
                },
                test: {
                    value: [],
                    inValid: true,
                    items: "paramsTest",
                },
                skills: {
                    value: [],
                    inValid: true,
                    items: "paramsSkills",
                },
                support: {
                    value: [],
                    inValid: true,
                    items: "paramsSupport",
                },
                technologyFields: {
                    value: [],
                    inValid: true,
                    items: "paramsTechnology",
                },
                innovationFields: {
                    value: [],
                    inValid: true,
                    items: "paramsInnovation",
                },
            },
        };
    },
    computed: {
        isValid() {
            return Object.values(this.inputs).some((input) => !input.inValid);
        },

        ...mapGetters({
            paramsEcosystem: "formValues/getEcosystemNetworking",
            paramsTest: "formValues/getTestBeforeInvest",
            paramsSkills: "formValues/getSkillsTraining",
            paramsSupport: "formValues/getSupportForInvestment",
            paramsTechnology: "formValues/getTechnologyFields",
            paramsInnovation: "formValues/getInnovationFields",
        }),

        computedShownFields() {
            return Object.entries(this.inputs).reduce((acc, [key, value]) => {
                if (this.userType == 2) {
                    if (
                        key === "innovationFields" ||
                        key === "technologyFields"
                    ) {
                        acc[key] = value;
                    }
                    return acc;
                } else {
                    acc[key] = value;
                    return acc;
                }
            }, {});
        },
    },
    methods: {
        updateInput(event, key) {
            this.$emit(`update:${key}`, event);
            this.inputs[key].inValid = event && event.length > 0 ? false : true;
        },

        getItems(params) {
            return this[params];
        },
    },

    watch: {
        isValid(newVal) {
            this.$emit("update:formValid", this.isValid);
        },

        userType(newVal) {
            Object.entries(this.inputs).forEach(([key, val]) => {
                val.value = [];
                this.updateInput([], key);
            });
        },
    },
};
</script>
