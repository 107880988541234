// import TheNavbar from "../components/base/TheNavbar.vue";
import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";
import store from "../store/index";

export default {
    path: "/profile/:id?",
    name: "",
    components: {
        default: () =>
            import(
                /* webpackChunkName: "UserProfile" */ "../views/userProfile/UserProfile.vue"
            ),
            navbar: TheNavigation,
            footer: TheFooter,
    },
    beforeEnter:((to, from, next) => {
        if(store.getters["auth/userIsAdmin"] && to.params.id == undefined) {
            next("/users");
        } else {
            next();
        }
    }),
    meta: {
        requiresAuth: true,
        title: 'Profile'
    },
    props: {
        default: true,
    },
    children: [
        {
            path: "",
            name: "UserProfile",
            component: () =>
            import(
                /* webpackChunkName: "UserProfile" */ "../views/userProfile/UserProfileEdit.vue"
            ),
            meta: {
                title: 'My Profile'
            }
        },

        {
            path: "favorites",
            name: "UserProfileFavorites",
            component: () =>
            import(
                /* webpackChunkName: "UserProfile" */ "../views/userProfile/UserProfileFavorites.vue"
            ),
            meta: {
                title: 'Favororites'
            }
        },  


        {
            path: "help",
            name: "UserProfileHelp",
            component: () =>
            import(
                /* webpackChunkName: "UserProfile" */ "../views/userProfile/UserProfileHelp.vue"
            ),
            meta: {
                title: 'Help'
            }
        },
    ],
}