import RouterView from "../views/RouterView.vue";
// import TheNavbar from "../components/base/TheNavbar.vue";
import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";

export default [
    {
        path: "/networking-opportunities",
        name: "",
        components: {
            default: RouterView,
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'Networking Oportunities'
        },

        children: [
            {
                path: "",
                name: "NetworkingOpportunities",
                component: () =>
                    import(
                        /* webpackChunkName: "NetworkingOpportunities" */ "../views/NetworkingOpportunities.vue"
                    ),
                    meta: {
                        title: 'Networking Oportunities'
                    }
            },
            {
                path: "create",
                name: "NetworkingOpportunitiesCreate",
                component: () =>
                    import(
                        /* webpackChunkName: "NetworkingOpportunities" */ "../views/NetworkingOpportunitiesCreate.vue"
                    ),
                    meta: {
                        title: 'Create Oportunity'
                    }
            },
            {
                path: ":id/update",
                name: "NetworkingOpportunitiesUpdate",
                component: () =>
                    import(
                        /* webpackChunkName: "NetworkingOpportunities" */ "../views/NetworkingOpportunitiesUpdate.vue"
                    ),
                    meta: {
                        title: 'Edit Oportunity'
                    },
                props: true,
            },

            {
                path: ":id",
                name: "NetworkingOpportunity",
                component: () =>
                    import(
                        /* webpackChunkName: "NetworkingOpportunity" */ "../views/NetworkingOpportunitiesDetails.vue"
                    ),
                    meta: {
                        title: 'Oportunity'
                    },
                props: true,
            },
        ],
    },

    {
        path: "/my-networking-opportunities",
        name: "MyNetworkingOpportunities",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "MyNetworkingOpportunities" */ "../views/NetworkingOpportunitiesUser.vue"
                ),
                navbar: TheNavigation,
                footer: TheFooter,
        },
        meta: {
            title: 'My Oportunities'
        }
    },
];
