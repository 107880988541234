<template>
    <v-container class="terms__container">
        <p class="text-body-1 font-weight-black">
            {{ $t("RegisterForm.TermsAndConds.title") }}
        </p>
        <p class="text-body-2">
            {{ $t("RegisterForm.TermsAndConds.termsStart") }}
        </p>
        <ul>
            <li>{{ $t("RegisterForm.TermsAndConds.termsFirst") }}</li>
            <li>{{ $t("RegisterForm.TermsAndConds.termsSecond") }}</li>
        </ul>
        <p class="text-body-2 mt-4">
            {{ $t("RegisterForm.TermsAndConds.termsThanks") }}
        </p>
        <v-row align="center" class="pa-0">
            <v-col cols="12" class="pb-0">
                <v-checkbox
                    v-model="termsAccepted"
                    dense
                    @change="updateTerms($event)"
                >
                    <template #label>
                        <span @click.stop>
                            I Agree to the
                            <router-link
                                :to="{ name: 'PrivacyTerms' }"
                                target="_blank"
                                class="primary--text text--darken-2"
                            >
                                terms and conditions
                            </router-link>
                        </span>
                    </template>
                </v-checkbox>
            </v-col>

            <v-col cols="12" class="py-0">
                <v-checkbox
                    v-model="membershipTerms"
                    dense
                    @change="updateTerms($event)"
                >
                    <template #label>
                        <span @click.stop>
                            I Agree to the
                            <router-link
                            target="_blank"
                                :to="termsLink"
                                class="primary--text text--darken-2"
                            >
                                membership terms
                            </router-link>
                        </span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "TermsAndConditions",

    props: {
        termsConditions: {
            type: Boolean,
            required: true,
        },

        userType: {
            type: Number,
            required: false,
            default: 2,
        },

        formValid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            termsAccepted: false,
            membershipTerms: false,
        };
    },

    computed: {
        formIsValid() {
            return this.termsAccepted && this.membershipTerms;
        },

        ...mapGetters({
            userRole: "auth/getUserRole",
        }),

        termsLink() {
            let name = "SmeTerms";
            if (this.userType == 1)
                return {
                    name: "DihTerms",
                };
            return {
                name,
            };
        },
    },

    methods: {
        updateTerms(event) {
            this.$emit("update:termsConditions", event);
            this.$emit("update:formValid", this.formIsValid);
        },
    },
};
</script>

<style scoped></style>
