import TheNavigation from "../components/base/TheNavigation.vue";
import TheFooter from "../components/base/TheFooter.vue";


export default [

    {
        path: "/my-solutions",
        name: "My Solutions",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "MySolutions" */ "../views/SME/Solutions.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
            title: 'My Solutions'
        },
    },

    {
        path: "/create-edit-solution/:id?",
        name: "Create - Edit Solution",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "CreateEditSolution" */ "../views/SME/CreateEditSolution.vue"
                ),
            navbar: TheNavigation,
            footer: TheFooter,
        },
        meta: {
            requiresAuth: true,
        },
        beforeEnter:((to, from, next) => {
            if(to.params.id) {
                document.title = `Edit Solution - ${to.params.id} | AgROBOfood`;
            } else {
                document.title = 'Create Solution | AgROBOfood';
            }
            next();
        }),
        props: {
            default: true,
        },
    },

]